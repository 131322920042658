import cx from 'classnames';
import Link from 'next/link';
import { ComponentProps, FunctionComponent, ReactNode } from 'react';

import Button from '../../../../ui/button';

type ListItemBrand = {
  id: number;
  slug: string;
  name: string;
  logo_url: string;
  is_top_brand: boolean;
  total_products: number;
  start_date?: number;
  end_date?: number;
  have_versions?: boolean;
};

type ListItemModel = {
  id?: number;
  slug: string;
  value: string;
  name: string;
  label: string;
  start_date: number;
  end_date: number;
  have_versions: boolean;
};

type ListItemVersion = {
  id: number;
  slug: string;
  name: string;
  start_date?: number;
  end_date?: number;
  have_versions?: boolean;
};

type ListItem = {
  href?: ComponentProps<typeof Link>['href'];
  as?: ComponentProps<typeof Link>['as'];
  tabIndex: number;
  children?: ReactNode;
  vehiclePickerListItem?: boolean;
  onClick?: () => void;
  value?: ListItemBrand | ListItemModel | ListItemVersion;
  isMobileView?: boolean;
};

const ListItem: FunctionComponent<ListItem> = ({
  href,
  tabIndex,
  children,
  as,
  vehiclePickerListItem,
  value,
  onClick,
  isMobileView = false,
}) => {
  if (vehiclePickerListItem) {
    const endDate = value?.end_date || new Date().getFullYear();

    return (
      <li className={cx('')}>
        <Button
          variant="DEFAULT"
          className={cx(
            'block lg:inline-block',
            'py-3.5',
            'transition-colors',
            'hover:bg-blue-10',
            'focus:bg-blue-10',
            'focus:outline-none',
            'h-fit',
            'text-left',
            vehiclePickerListItem ? ' px-4' : '!px-0',
            {
              '!flex items-center justify-center': isMobileView,
            }
          )}
          type="button"
          tabIndex={tabIndex}
          onClick={onClick}
        >
          {children || (
            <>
              <span className={cx('text-base', 'font-bold')}>
                {value?.name}
              </span>
              <span className={cx('text-base', 'lg:ml-0', 'block')}>
                [{value?.start_date}-{endDate}]
              </span>
            </>
          )}
        </Button>
      </li>
    );
  }

  return (
    <li
      className={cx('w-full lg:w-auto max-lg:flex max-lg:justify-center', {
        'flex justify-center': isMobileView,
      })}
    >
      <Link
        href={href}
        as={as}
        passHref
        shallow
        scroll
        className={cx(
          'block lg:inline-block',
          'p-3.5',
          'transition-colors',
          'hover:bg-blue-10',
          'focus:bg-blue-10',
          'focus:outline-none',
          'w-full max-lg:flex max-lg:justify-center',
          {
            'lg:mx-3': !isMobileView,
            '!flex justify-center': isMobileView,
          }
        )}
        tabIndex={tabIndex}
      >
        {children}
      </Link>
    </li>
  );
};

export default ListItem;
