import cx from 'classnames';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';

import IconCheck from '../../../icons/check';

type PartNameWithStockProps = {
  name: ReactNode;
  slug: string;
  display?: 'column' | 'inline';
  inverted?: boolean;
  isChecked?: boolean;
  stockWithBg?: boolean;
  displayCheck?: boolean;
  disabled?: boolean;
  showStock?: boolean;
  nameTextTag?: keyof JSX.IntrinsicElements;
  totalStock: number;
};

const PartNameWithStock = ({
  name,
  slug,
  display = 'inline',
  inverted = false,
  isChecked = false,
  nameTextTag: As = 'span',
  stockWithBg = false,
  displayCheck = true,
  disabled = false,
  totalStock,
  showStock = true,
}: PartNameWithStockProps) => {
  const { locale = 'en' } = useRouter();

  return (
    <div
      className={cx('min-h-12 py-2 text-left flex gap-2 flex-row justify-between items-center grow-0 text-wrap', {
        'text-neutral-50': totalStock === 0,
        'font-bold': !inverted,
      })}
    >
      <As className="text-left">{name}</As>
      {totalStock !== null && (
        <span
          className={cx(
            'text-xs',
            'relative',
            {
              'font-normal': !inverted,
              'font-bold': inverted,
              'flex gap-4': isChecked,
            },
            'flex justify-end'
          )}
        >
          {isChecked && displayCheck && (
            <IconCheck className="h-4 w-4 justify-self-end text-brand-100" />
          )}
          {showStock && (
            <div
              className={cx(
                {
                  'text-neutral-80 p-1 font-bold text-xs': stockWithBg,
                },
                {
                  'bg-brand-10 ': stockWithBg && !isChecked,
                },
                {
                  'bg-white ': stockWithBg && isChecked,
                },
                'flex justify-end group-hover:bg-white'
              )}
            >
              {totalStock?.toLocaleString(locale)}
            </div>
          )}
        </span>
      )}
    </div>
  );
};

export default PartNameWithStock;
