import { fechFromBPartsApi } from '../utils';

export const fetchUserCars = async ({
  locale,
  csrftoken,
}: {
  locale: string;
  csrftoken: string;
}): Promise<TCarCompleteInfo[]> => {
  const data = await fechFromBPartsApi(`/${locale}/shop/user_cars/list-last`, {
    headers: new Headers([
      ['Content-Type', 'application/json'],
      ['X-CSRFToken', csrftoken],
    ]),
    credentials: 'include',
  });
  return data.json();
};

export default fetchUserCars;
