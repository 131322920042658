import { SVGProps } from 'react';

const IconCloseRounded = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 8C0 3.58323 3.58323 0 8 0C12.4168 0 16 3.58323 16 8C16 12.4168 12.4168 16 8 16C3.58323 16 0 12.4168 0 8ZM8 1C4.13552 1 1 4.13552 1 8C1 11.8645 4.13552 15 8 15C11.8645 15 15 11.8645 15 8C15 4.13552 11.8645 1 8 1Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.14645 5.14645C5.34171 4.95118 5.65829 4.95118 5.85355 5.14645L10.8536 10.1464C11.0488 10.3417 11.0488 10.6583 10.8536 10.8536C10.6583 11.0488 10.3417 11.0488 10.1464 10.8536L5.14645 5.85355C4.95118 5.65829 4.95118 5.34171 5.14645 5.14645Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.8536 5.14645C11.0488 5.34171 11.0488 5.65829 10.8536 5.85355L5.85355 10.8536C5.65829 11.0488 5.34171 11.0488 5.14645 10.8536C4.95118 10.6583 4.95118 10.3417 5.14645 10.1464L10.1464 5.14645C10.3417 4.95118 10.6583 4.95118 10.8536 5.14645Z"
      fill="currentColor"
    />
  </svg>
);

export default IconCloseRounded;
